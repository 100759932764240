import React from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import rxntHero from '../../../../../images/solutions/middle-section/banners/rxnt-banner.webp';
import hopscotchHero from '../../../../../images/solutions/middle-section/banners/hopscotch-banner.webp';
import { useAppContext } from "../../../../../context/app-context";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;

    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
}

const HealthcareCarousel = () => {
    const { setSelectedIndustryProd } = useAppContext()

    const slides = [
        {
            productName: "rxnt",
            content: (
                <div className="text-center">
                    <img src={rxntHero} alt="hero" className="img-fluid mx-auto" />
                </div>
            ),
        },
        {
            productName: "hopscotch",
            content: (
                <div className="text-center">
                    <img src={hopscotchHero} alt="hero" className="img-fluid mx-auto" />
                </div>
            ),
        },
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        afterChange: (current) => {
            setSelectedIndustryProd(slides[current].productName)
        },
    };

    return (
        <section className="solutions-carousel-section">
            <Container>
                <Slider {...settings}>
                    {slides.map((slide, index) => (
                        <div key={index}>{slide.content}</div>
                    ))}
                </Slider>
            </Container>
        </section>
    );
};

export default HealthcareCarousel;

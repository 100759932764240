import React from "react";
import { Container } from "react-bootstrap";

const PrivacyPolicy = () => {
    return (
        <Container className="privacy-policy py-4 text-primary">
            <h1 className="fw-bold mb-4">Privacy Policy</h1>

            <section>
                <h4 class="fw-bold">1. Introduction</h4>
                <p>
                    ACube Technologies ("we," "us," or "our") is committed to protecting the privacy of
                    individuals and organizations who interact with us, including clients, website visitors,
                    and users of our services. This Privacy Policy outlines our practices concerning the
                    collection, use, disclosure, and protection of personal information in compliance with
                    applicable Indian laws, including the Information Technology Act, 2000, and the
                    Information Technology (Reasonable Security Practices and Procedures and
                    Sensitive Personal Data or Information) Rules, 2011.
                </p>
            </section>

            <section>
                <h4 class="fw-bold">2. Information We Collect</h4>
                <ul>
                    <li><strong>Personal Information:</strong> This includes name, email address, phone number, postal address, and other contact details provided by you voluntarily.</li>
                    <li><strong>Business Information:</strong> Information related to the organization you represent, including business name, job title, and business contact information.</li>
                    <li><strong>Technical Information:</strong> Information about your device, IP address, browser type, operating system, and browsing behavior on our website through cookies and other tracking technologies.</li>
                    <li><strong>Service Data:</strong> Information collected in the course of providing our software consulting services, which may include project requirements, code, data files, and other information relevant to the services we provide.</li>
                    <li><strong>Payment Information:</strong> If applicable, we may collect billing and payment information for the processing of service-related transactions.</li>
                </ul>
            </section>

            <section>
                <h4 class="fw-bold">3. How We Use Your Information</h4>
                <ul>
                    <li>To provide, maintain, and improve our software consulting services.</li>
                    <li>To communicate with you about your projects, inquiries, and support requests.</li>
                    <li>To personalize your experience on our website.</li>
                    <li>To process payments and manage billing.</li>
                    <li>To comply with legal obligations and protect our legal rights.</li>
                    <li>To send you promotional materials or newsletters, if you have opted in to receive them.</li>
                </ul>
            </section>

            <section>
                <h4 class="fw-bold">4. Sharing Your Information</h4>
                <ul>
                    <li><strong>Service Providers:</strong> We may share information with trusted third-party service providers who assist us in operating our business, such as payment processors, hosting providers, and analytics companies. These service providers are obligated to protect your information and use it only for the purposes specified by us.</li>
                    <li><strong>Legal Compliance:</strong> We may disclose information if required by law, legal process, or government request.</li>
                    <li><strong>Business Transfers:</strong> If we merge, acquire, or sell any part of our business, your information may be transferred as part of the business assets.</li>
                    <li><strong>Consent:</strong> We may share your information with your consent for any other purpose not listed above.</li>
                </ul>
            </section>

            <section>
                <h4 class="fw-bold">5. Security of Your Information</h4>
                <p>
                    We take reasonable steps to implement and maintain appropriate technical and organizational security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee its absolute security.
                </p>
            </section>

            <section>
                <h4 class="fw-bold">6. Data Retention</h4>
                <p>
                    We retain your personal information only for as long as necessary to fulfill the purposes for which it was collected, comply with legal obligations, resolve disputes, and enforce agreements.
                </p>
            </section>

            <section>
                <h4 class="fw-bold">7. Your Rights</h4>
                <p>
                    You have the right to access, correct, update, or delete your personal information held by us. If you wish to exercise these rights or have any questions about your personal information, please contact us at [Contact Email Address].
                </p>
            </section>

            <section>
                <h4 class="fw-bold">8. Cookies and Tracking Technologies</h4>
                <p>
                    Our website may use cookies and similar tracking technologies to enhance your experience. You can manage your cookie preferences through your browser settings. By using our website, you consent to the use of cookies as described in this Privacy Policy.
                </p>
            </section>

            <section>
                <h4 class="fw-bold">9. Third-Party Websites</h4>
                <p>
                    Our website may contain links to third-party websites. We are not responsible for the privacy practices or the content of those websites. We encourage you to review the privacy policies of any third-party sites you visit.
                </p>
            </section>

            <section>
                <h4 class="fw-bold">10. Changes to This Privacy Policy</h4>
                <p>
                    We may update this Privacy Policy from time to time. Any changes will be effective immediately upon posting the updated Privacy Policy on our website. We encourage you to review this Privacy Policy periodically to stay informed about our information practices.
                </p>
            </section>

            <section>
                <h4 class="fw-bold">11. Contact Us</h4>
                <p>
                    If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
                    <br /><br />
                    <span className="fw-semibold">
                        ACubetech solutions Pvt Ltd<br />
                        Brigade IRV, 3rd Floor.<br />
                        Nellurhalli, Whitefield,<br />
                        Bangalore - 560066, India.<br />
                        <br />
                        +91 9731 800 800
                        <br />
                        info@acubetechnologies.in
                    </span>
                </p>
            </section>

            <section>
                <h4 class="fw-bold">12. Grievance Officer</h4>
                <p>
                    In compliance with Indian law, we have designated a Grievance Officer to address any grievances or concerns regarding the processing of personal information. You may contact the Grievance Officer at:
                    <br /> <br />
                    <span className="fw-semibold">Name:</span> Anand C
                    <br />
                    <span className="fw-semibold">Email:</span> hr-support@acubetechnologies.in
                    <br />
                    <span className="fw-semibold">Phone:</span> +91 8042 021 000
                    <br />
                    <span className="fw-semibold">Address:</span>  ACubetech solutions Pvt Ltd<br />
                    Brigade IRV, 3rd Floor.<br />
                    Nellurhalli, Whitefield,<br />
                    Bangalore - 560066, India.<br />
                </p>
            </section>

            <p>
                By using our services or accessing our website, you agree to the terms of this Privacy Policy.
            </p>
        </Container>
    );
};

export default PrivacyPolicy;

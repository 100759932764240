import { Container, Row, Col, Button } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { useScroll } from '../../context/scroll-context';

const FAQSection = () => {
    const { faq } = useScroll();
    return (
        <section className="faq-section" ref={faq}>
            <Container fluid className="p-4 p-lg-5 flex-column d-flex">
                <Row className="justify-content-center">
                    <Col xs="12">
                        <h1 className="texrt-primary ft-wt-600 text-center text-uppercase mb-4">FAQ</h1>
                        <Accordion className="d-flex flex-column gap-4">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>What business models do you offer?</Accordion.Header>
                                <Accordion.Body>
                                    We offer two engagement models:
                                    <ul>
                                        <li><span className="fw-bold text-primary">Time &amp; Material:</span> Hire full-time or part-time developers to seamlessly integrate with your team.</li>
                                        <li><span className="fw-bold text-primary">Fixed Price:</span> Provide us with your project requirements, and we'll give you a detailed estimate, including time, cost, and a project roadmap based on our evaluation.</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>What is the 2-week risk-free trial period, and how does it work?</Accordion.Header>
                                <Accordion.Body>
                                    Our 15-day risk-free trial allows you to assess our code quality, communication, timely delivery, and Agile development practices. Experience our services with no commitment and decide if we meet your expectations, all risk-free!
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Do I work directly with the developer?</Accordion.Header>
                                <Accordion.Body>
                                    Yes, you will work directly with the developer.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>How can I transition from my existing vendor/developer to ACube?</Accordion.Header>
                                <Accordion.Body>
                                    We understand the challenges of switching vendors. Our experienced developers are skilled in taking over ongoing projects and resolving development and management issues. Using advanced project management tools, we ensure smooth and efficient project delivery with clear communication, daily stand-ups, and regular retrospectives.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>What tools do you use to manage projects?</Accordion.Header>
                                <Accordion.Body>
                                    We utilize top project management tools such as Jira, Asana, and Slack. These platforms allow you to monitor developer productivity, track project progress, assign tasks, and manage the development process efficiently.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>How does ACube ensure the security and confidentiality of our IP?</Accordion.Header>
                                <Accordion.Body>
                                    We are an <span className="fw-bold text-primary">ISO 27001:2022</span> certified company. ISO 27001's risk-based approach helps us mitigate security risks and breaches by prioritizing them appropriately. You will have full ownership of your project, including all rights to NDAs, copyright, source code, and intellectual property. We prioritize the security and confidentiality of your project.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>What are the working hours of the developer?</Accordion.Header>
                                <Accordion.Body>
                                    Developers will work according to your time zone, with at least 3 hours of overlapping availability, ensuring comprehensive coverage across your time zone.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7">
                                <Accordion.Header>What is your payment method?</Accordion.Header>
                                <Accordion.Body>
                                    We are ACUBE USA LLC, a registered entity in Nevada, United States. Payments can be made via <span className="fw-bold text-primary">ACH</span> or wire transfer to our <span className="fw-bold text-primary">Choice Bank account.</span> We can also assist with tax savings using a W9 form. Our Business Identification Number is NV20243142956. We accept wire transfers from anywhere in the world.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="8">
                                <Accordion.Header>Who will be guiding the developer, and how can I ensure they follow industry best practices?</Accordion.Header>
                                <Accordion.Body>
                                    We will assign a dedicated Technical Architect and Scrum Master to oversee the project. They will guide the developer and ensure adherence to industry best practices. They will also be your main point of contact for any assistance, queries, or resolutions.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="9">
                                <Accordion.Header>What if I am not satisfied with the hired developer's work and want to exit?</Accordion.Header>
                                <Accordion.Body>
                                    Your satisfaction is our top priority. If you are not satisfied, either party can terminate the contract with 14 days' written notice. All completed work will be delivered to you in a usable electronic format after clearing any outstanding invoices. If you decide to exit during the initial 2-week risk-free trial, you won't be liable for any payment.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <div className="text-center mt-5">
                            <a href="https://calendly.com/acube/discovery-call?month=2024-07" rel="noreferrer" target="_blank">
                                <Button className="text-uppercase" variant="primary">
                                    Have More Questions? Let’s Connect!
                                </Button>
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default FAQSection;

import React from "react";
import Container from "react-bootstrap/Container";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../images/Acube-logo-blue.svg";
import "./header.css";


function ErrorHeader() {
    const location = useLocation();
    const navigate = useNavigate();

    const handlePageLink = (path) => {
        navigate(path);
    };

    const handleHomePageScroll = (path) => {
        if (location.pathname === path) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        else {
            handlePageLink(path)
        }

    }

    return (
        <Navbar
            collapseOnSelect
            expand="xl"
            className="bg-body-tertiary page-header fixed-top"
        >
            <Container fluid>
                <Navbar.Brand onClick={() => handleHomePageScroll("/")}>
                    <img
                        src={logo}
                        alt="logo"
                        width="130"
                        height="45"
                        className="img-fluid d-block d-lg-none"
                    />
                    <img
                        src={logo}
                        alt="logo"
                        width="192"
                        height="45"
                        className="img-fluid d-none d-lg-block"
                    />
                </Navbar.Brand>
            </Container>
        </Navbar>
    );
}

export default ErrorHeader;

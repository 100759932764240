import React from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import opennHero from '../../../../../images/solutions/middle-section/banners/openn-banner.webp';
import kobelcoHero from '../../../../../images/solutions/middle-section/banners/kobelco-banner.webp';

import { useAppContext } from "../../../../../context/app-context";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;

    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
}

const RealEstateCarousel = () => {
    const { setSelectedIndustryProd } = useAppContext()

    const slides = [
        {
            productName: "openn",
            content: (
                <div className="text-center">
                    <img src={opennHero} alt="hero" className="img-fluid mx-auto" />
                </div>
            ),
        },
        {
            productName: "kobelco",
            content: (
                <div className="text-center">
                    <img src={kobelcoHero} alt="hero" className="img-fluid mx-auto" />
                </div>
            ),
        },
    ];

    const settings = {
        dots: false,
        infinite: false,  // Disable infinite scrolling with one item
        slidesToShow: slides.length > 1 ? 1 : slides.length, // Show the correct number of slides
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        afterChange: (current) => {
            setSelectedIndustryProd(slides[current].productName);
        },
    };


    return (
        <section className="solutions-carousel-section">
            <Container>
                <Slider {...settings}>
                    {slides.map((slide, index) => (
                        <div key={index}>{slide.content}</div>
                    ))}
                </Slider>
            </Container>
        </section>
    );
};

export default RealEstateCarousel;

import React from "react";
import medixo from '../../../images/home/medixo.svg'
import taxongo from '../../../images/home/tax-on-go.svg'
import tracxn from '../../../images/home/tracxn.svg'
import microsoft from '../../../images/home/microsoft.svg'
import aws from '../../../images/home/aws.svg'
import googleCloud from '../../../images/home/google-cloud.svg'
import ambitionbox from '../../../images/home/ambitionbox.svg'
import clutch from '../../../images/home/clutch-logo.svg'
import googleReviews from '../../../images/home/google-review.svg'
import glassdoor from '../../../images/home/glassdoor.svg'
import { Container, Row, Col } from "react-bootstrap";

const ProductPartners = () => {
    return (
        <>
            <section className="product-partners-wrapper">
                <Container fluid>
                    <Row className="d-flex position-relative">
                        <Col xs="12" lg="3" className="product-section d-flex flex-column">
                            <h1 className="text-primary ft-wt-600 text-uppercase mb-4 text-center">Our Products</h1>
                            <Row className="gap-0 gap-lg-4 justify-content-center">
                                <Col xs="4" lg="12" className="d-flex align-items-center justify-content-center my-0 my-lg-3">
                                    <img src={medixo} alt="medixo" loading="lazy" className="img-fluid" />
                                </Col>
                                <Col xs="4" lg="12" className="d-flex align-items-center justify-content-center my-0 my-lg-3">
                                    <img src={taxongo} alt="taxongo" loading="lazy" className="img-fluid" />
                                </Col>
                                <Col xs="4" lg="12" className="d-flex align-items-center justify-content-center my-0 my-lg-3">
                                    <img src={tracxn} alt="tracxn" loading="lazy" className="img-fluid" />
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={12} lg={1} className="d-none d-lg-flex justify-content-center">
                            <div className="vertical-divider"></div>
                        </Col>

                        <Col xs={12} lg={3} className="partner-section mt-4 mt-lg-0 d-flex flex-column">
                            <h1 className="text-primary ft-wt-600 text-uppercase mb-4 text-center">Our Partners</h1>
                            <Row className="gap-0 gap-lg-4 justify-content-center">
                                <Col xs="4" lg="12" className="d-flex align-items-center justify-content-center my-3">
                                    <img src={microsoft} alt="microsoft" loading="lazy" className="img-fluid" />
                                </Col>
                                <Col xs="4" lg="12" className="d-flex align-items-center justify-content-center my-3">
                                    <img src={aws} alt="aws" loading="lazy" className="img-fluid" />
                                </Col>
                                <Col xs="4" lg="12" className="d-flex align-items-center justify-content-center my-3">
                                    <img src={googleCloud} alt="google-cloud" loading="lazy" className="img-fluid" />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} lg={1} className="d-none d-lg-flex justify-content-center">
                            <div className="vertical-divider"></div>
                        </Col>
                        <Col xs={12} lg={3} className="partner-section mt-4 mt-lg-0">
                            <h1 className="text-primary ft-wt-600 text-uppercase mb-4 text-center">Our Ratings</h1>
                            <Row className="justify-content-center">
                                <Col xs="6" lg="12" className="my-1 d-flex align-items-center justify-content-center">
                                    <a className="cursor-pointer" href="https://clutch.co/profile/acubetech-solutions#highlights" rel="noreferrer" target="_blank" >
                                        <div>
                                            <img src={clutch} alt="clutch" loading="lazy" className="img-fluid mb-2" />
                                            <h6 className="text-primary text-center fw-bold text-decoration-underline">Know More</h6>
                                        </div>
                                    </a>
                                </Col>
                                <Col xs="6" lg="12" className="d-flex align-items-center justify-content-center my-1">
                                    <a className="cursor-pointer" href="https://www.ambitionbox.com/reviews/acubetech-solutions-reviews" rel="noreferrer" target="_blank" >
                                        <div>
                                            <img src={ambitionbox} alt="ambitionbox" loading="lazy" className="img-fluid mb-2" />
                                            <h6 className="text-primary text-center fw-bold text-decoration-underline">Know More</h6>
                                        </div>
                                    </a>
                                </Col>
                                <Col xs="6" lg="12" className="d-flex align-items-center justify-content-center my-1">
                                    <a className="cursor-pointer" href="https://www.google.com/search?q=ACubetech+Solutions+Pvt+Ltd" rel="noreferrer" target="_blank" >
                                        <div>
                                            <img src={googleReviews} alt="google-review" loading="lazy" className="img-fluid mb-2" />
                                            <h6 className="text-primary text-center fw-bold text-decoration-underline">Know More</h6>
                                        </div>
                                    </a>
                                </Col>
                                <Col xs="6" lg="12" className="d-flex align-items-center justify-content-center my-3">
                                    <a className="cursor-pointer" href="https://www.glassdoor.co.in/Reviews/ACubetech-Solutions-Reviews-E1344716.htm" rel="noreferrer" target="_blank" >
                                        <div>
                                            <img src={glassdoor} alt="glassdoor" loading="lazy" className="img-fluid mb-2" />
                                            <h6 className="text-primary text-center fw-bold text-decoration-underline">Know More</h6>
                                        </div>
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default ProductPartners;

import React, { createContext, useContext, useState } from "react";

// Create the context
const AppContext = createContext();

// Create a provider component
export const AppProvider = ({ children }) => {
    const [exploreNow, setExploreNow] = useState(false);
    const [navbarExpanded, setNavbarExpanded] = useState(false);
    const [selectedIndustryProd, setSelectedIndustryProd] = useState("")
    const [showMegaMenu, setShowMegaMenu] = useState({
        services: false,
        hireTeam: false,
    });

    // Define the context value
    const contextValue = {
        exploreNow,
        setExploreNow,
        showMegaMenu,
        setShowMegaMenu,
        navbarExpanded,
        setNavbarExpanded,
        selectedIndustryProd,
        setSelectedIndustryProd
    };

    return (
        <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
    );
};

// Custom hook to use the context
export const useAppContext = () => {
    return useContext(AppContext);
};
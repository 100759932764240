import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/footer/footer";
import ErrorHeader from "../components/header/error-header";

const ErrorPageLayout = () => {

    return (
        <>
            <ErrorHeader />
            <div className="main-section">
                <Outlet />
            </div>
            <Footer />
        </>
    );
};

export default ErrorPageLayout;

import React from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import svhsHero from '../../../../../images/solutions/middle-section/banners/svhs-banner.webp';
import { useAppContext } from "../../../../../context/app-context";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;

    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
}

const EducationCarousel = () => {
    const { setSelectedIndustryProd } = useAppContext()

    const slides = [
        {
            productName: "svhs",
            content: (
                <div className="text-center">
                    <img src={svhsHero} alt="hero" className="img-fluid mx-auto" />
                </div>
            ),
        }
    ];

    const settings = {
        dots: false,
        infinite: slides.length > 1,
        speed: 500,
        slidesToShow: slides.length > 1 ? 1 : slides.length, // Show the correct number of slides
        slidesToScroll: 1,
        nextArrow: slides.length > 1 ? <SampleNextArrow /> : null,  // Show arrows only if more than 1 slide
        prevArrow: slides.length > 1 ? <SamplePrevArrow /> : null,
        afterChange: (current) => {
            setSelectedIndustryProd(slides[current].productName)
        },
    };

    return (
        <section className="solutions-carousel-section">
            <Container>
                <Slider {...settings}>
                    {slides.map((slide, index) => (
                        <div key={index}>{slide.content}</div>
                    ))}
                </Slider>
            </Container>
        </section>
    );
};

export default EducationCarousel;

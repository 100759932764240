import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useScroll } from "../../../../context/scroll-context";

const MiddleSection = ({ content, mockAlt, mockImg, title }) => {
    const { scrollToSection, contactform } = useScroll();

    const handleScrollAndClose = (refName) => {
        scrollToSection(refName);
    };
    return (
        <section className="middle-section my-4 mb-3 mb-lg-5 p-3">
            <Container fluid>
                <Row className="justify-content-center">
                    <Col xs="12" lg="6">
                        <div className="text-primary d-flex flex-column gap-4 mb-4 mb-lg-0">
                            {content}
                        </div>
                    </Col>
                    <Col xs="12" lg="6" className="text-center">
                        <img src={mockImg} alt={mockAlt} className="img-fluid" loading="lazy" />
                    </Col>
                    <Col xs="12" className="text-center mt-5">
                        <Button variant="primary" className="text-uppercase h-auto" onClick={() => handleScrollAndClose(contactform)}>click here to start your {title} journey</Button>
                    </Col>
                </Row>
            </Container>

        </section>
    )
}

export default MiddleSection
import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import SolutionsHero from "./components/hero";
import { solutionsContentConfig, ProductContentConfig } from "../../../config/solutions-content-config";
import BrandsCarousel from "../../../components/carousels/brands-carousel";
import MiddleSection from "./components/middle-section";
import RelatedProductsCarousel from "./components/carousels/related-products-carousel";
import { useAppContext } from "../../../context/app-context";
import TestimonialVideoPlayer from "../../../components/videos/testimonial-video";
import ContactForm from "../../../components/forms/contact-form";
import FAQSection from "../../../components/accordion/faq";
import ProductPartners from "../../home/sections/product-partners";
import CompanyInsights from "../../home/sections/company-insights";
import TestimonialCarousel from "../../../components/carousels/testimonial-carousel";
import { carouselConfig } from "../../../config/carousel-config"; // Import your carousel config
import '../../home/home.css';
import SuccessStoriesTestimonial from "./components/carousels/success-stories-carousel";
import TechnologySection from "./components/technology-section";

const SolutionsBaseLayout = ({ pageName }) => {
    const { setSelectedIndustryProd, selectedIndustryProd } = useAppContext();

    useEffect(() => {
        const filteredProducts = ProductContentConfig.filter(
            (product) => product.productParent === pageName
        );
        if (filteredProducts.length > 0) {
            setSelectedIndustryProd(filteredProducts[0].productName);
        }
    }, [pageName, setSelectedIndustryProd]);

    const page = solutionsContentConfig?.find((data) => data?.pageName === pageName);

    // if (!page) return null;

    const product = ProductContentConfig?.find((data) => data?.productName === selectedIndustryProd);

    // if (!product) return null;

    // Get carousels based on pageName
    const carousels = carouselConfig[pageName] || [];

    return (
        <section className="solutions layout-wrapper">
            <SolutionsHero
                title={page?.title}
                backgroundImage={page?.backgroundImage}
                content={page?.heroContent}
                highlightedContent={page?.highlightedContent}
            />
            <BrandsCarousel />
            <Container fluid className="pt-5 pb-3 px-4">
                <Row>
                    <Col xs="12">
                        <h1 className="text-primary mb-4 solutions-section-title">
                            {page?.title} Businesses we've helped scale to success
                        </h1>
                        <h4 className="text-primary solutions-section-subtitle">
                            At ACUBE, we've successfully supported numerous entrepreneurs in building their {page?.title} products from the ground up, guiding them all the way to IPO. Our highly skilled team delivers <span className="fw-semibold">MVPs (Minimum Viable Products) in just 2 to 4 weeks</span>, enabling rapid product launches and market entry.
                        </h4>
                        <h1 className="text-primary fw-bold mt-5 mb-4 text-center text-uppercase big-title">{product?.title}</h1>
                    </Col>
                </Row>
            </Container>
            {/* Render carousels dynamically */}
            {carousels.map((carousel) => carousel)}
            <TechnologySection techImg={product?.ProductTechImg} techAlt={product?.ProductTechImgAlt} title={product?.title} />
            <MiddleSection content={product?.productContent} mockAlt={product?.ProductMockImgAlt} mockImg={product?.ProductMockImg} title={page?.title} />
            <SuccessStoriesTestimonial />

            <RelatedProductsCarousel pageName={pageName} />
            <ContactForm />
            <CompanyInsights />
            <TestimonialVideoPlayer />
            <TestimonialCarousel />
            <FAQSection />
            <ProductPartners />
        </section>
    );
};

export default SolutionsBaseLayout;

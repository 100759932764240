import hospitalityHero from '../images/solutions/hero/hospitality.webp'
import healthcareHero from '../images/solutions/hero/Health-care.webp'
import ecommerceHero from '../images/solutions/hero/E-commerce.webp'
import realestateHero from '../images/solutions/hero/Real-estate.webp'
import fintechHero from '../images/solutions/hero/fintech.webp'
import gpstrackHero from '../images/solutions/hero/GPS-Tracking.webp'
import educationHero from '../images/solutions/hero/Education.webp'

import frankmockupImg from '../images/solutions/middle-section/frank-mockup.webp'
import rxntmockupImg from '../images/solutions/middle-section/mockup/rxnt-mockup.webp'
import svhsmockupImg from '../images/solutions/middle-section/mockup/svhs-mockup.webp'
import asnmockupImg from '../images/solutions/middle-section/mockup/asn-mockup.webp'
import mobimockupImg from '../images/solutions/middle-section/mockup/mobi-mockup.webp'
import dRiskmockupImg from '../images/solutions/middle-section/mockup/d-risk-mockup.webp'
import kobelcomockupImg from '../images/solutions/middle-section/mockup/kobelco-mockup.webp'
import qicsendmockupImg from '../images/solutions/middle-section/mockup/quicsend-mockup.webp'
import hopscotchmockupImg from '../images/solutions/middle-section/mockup/hopscotch-mockup.webp'
import rampmockupImg from '../images/solutions/middle-section/mockup/ramp-mockup.webp'
import opennmockupImg from '../images/solutions/middle-section/mockup/openn-mockup.webp'
import anatomiemockupImg from '../images/solutions/middle-section/mockup/anatomie-mockup.webp'

import franktechImg from '../images/solutions/tech/frank-tech.webp'
import rxnttechImg from '../images/solutions/tech/rxnt-tech.webp'
import svhstechImg from '../images/solutions/tech/svhs-tech.webp'
import asntechImg from '../images/solutions/tech/svhs-tech.webp'
import mobitechImg from '../images/solutions/tech/mobi-car-tech.webp'
import dRisktechImg from '../images/solutions/tech/d-risk-tech.webp'
import kobelcotechImg from '../images/solutions/tech/kobelco-tech.webp'
import qicsendtechImg from '../images/solutions/tech/qicsend-tech.webp'
import hopscotchtechImg from '../images/solutions/tech/hopscotch-tech.webp'
import ramptechImg from '../images/solutions/tech/ramp-tech.webp'
import openntechImg from '../images/solutions/tech/openn-tech.webp'
import anatomietechImg from '../images/solutions/tech/anatomie-tech.webp'


export const solutionsContentConfig = [
    {
        pageName: "hospitality",
        title: "Hospitality / Restaurants",
        heroContent: "In 2024, the global restaurant and hospitality market is expected to reach around $5.5 trillion, with the foodservice sector projected to surpass $1 trillion in the U.S. alone​. This growth is driven by increasing demand for technology-enabled services and a stronger emphasis on customer experience.",
        highlightedContent: "Join with us to Build world class Hospitality applications.",
        backgroundImage: hospitalityHero,
    },
    {
        pageName: "healthcare",
        title: "Healthcare / Telemedicine",
        heroContent: "In 2024, the global healthcare IT market is projected to reach $426 billion, growing at a 16-17% CAGR​, Market Data Forecast. This growth is fueled by the adoption of technologies like electronic health records (EHR), telehealth, and AI-based solutions.",
        highlightedContent: "Partner with us to Build world class Healthcare applications.",
        backgroundImage: healthcareHero,
    },
    {
        pageName: "education",
        title: "Education / E-Learning",
        heroContent: "In 2024, the global education technology (EdTech) market is expected to reach $153.5 billion, growing at a 20% CAGR through 2029. This surge is driven by the widespread adoption of digital learning platforms, AI-powered tools, and cloud-based solutions in both K-12 and higher education​.",
        highlightedContent: "Partner with us to Build world class Education applications.",
        backgroundImage: educationHero,
    },
    {
        pageName: "ecommerce",
        title: "E-Commerce",
        heroContent: "In 2024, the global e-commerce market is expected to reach $8.8 trillion, growing at a CAGR of 15.8% through 2029. The growth is driven by increasing internet penetration, mobile commerce, and technological advancements like AI and real-time data analytics​.",
        highlightedContent: "Partner with us to Build world class E-Commerce Solutions.",
        backgroundImage: ecommerceHero,
    },
    {
        pageName: "fintech",
        title: "Fintech",
        heroContent: "In 2024, the global fintech market is projected to be worth approximately $312.92 billion, with a CAGR of over 14% through 2029. This growth is driven by the increasing adoption of digital payments, blockchain, and AI technologies.",
        highlightedContent: "Partner with us to Build world class Fintech applications.",
        backgroundImage: fintechHero,
    },
    {
        pageName: "realEstate",
        title: "Real Estate",
        heroContent: "In 2024, the global IT market in the real estate industry is expected to reach $10.54 billion, growing at a CAGR of 10.32% and projected to hit $17.22 billion by 2029. This growth is driven by the increasing adoption of cloud solutions, CRM, ERP systems, and business intelligence tools to enhance operational efficiency and data management​.",
        highlightedContent: "Partner with us to Build world class Real Estate applications.",
        backgroundImage: realestateHero,
    },
    {
        pageName: "gpsTracking",
        title: "GPS Tracking / Location-Based Apps",
        heroContent: "In 2024, the global GPS-based Application market is projected to experience significant growth, driven by increasing smartphone penetration and demand for location-based services. The market is expected to grow at a CAGR of 16.5%, with applications in logistics, transportation, and consumer devices leading the way.",
        highlightedContent: "Partner with us to Build world class GPS-based applications.",
        backgroundImage: gpstrackHero,
    }
];





export const ProductContentConfig = [
    // hospitality
    {
        productParent: "hospitality",
        productName: "frank",
        title: "Frank",
        productContent: [
            <div key="frank-overview">
                <h1 className="text-primary fw-semibold mb-3">Overview</h1>
                <p>Across Australia each year, over 1.4 million instances of food poisoning take place. 120 of these instances result in death. In restaurants, cafes, and other food businesses across the country, these are potentially preventable and unnecessary.</p>
            </div>,

            <div key="frank-solution">
                <h1 className="text-primary fw-semibold mb-3">The challenge and our solution</h1>
                <p>FRANK is a food safety application that removes the burden of pen and paper, making it easier for food-based businesses to record their food temperatures and ensure they are compliant.</p>
            </div>,

            <div key="frank-expertise">
                <h1 className="text-primary fw-semibold mb-3">Expertise</h1>
                <p>The founder brings 25 years of food industry and auditing experience to the table. FRANK has been designed not to fully automate food safety processes, but rather to enhance the responsibility and accountability within the team to ensure greater awareness and ultimately, preventative action.</p>
            </div>
        ],
        ProductMockImg: frankmockupImg,
        ProductMockImgAlt: "frankmockupImgAlt",
        ProductTechImg: franktechImg,
        ProductTechImgAlt: "franktechImgAlt",
    },

    // healthcare
    {
        productParent: "healthcare",
        productName: "rxnt",
        title: "RXNT",
        productContent: [
            <div key="rxnt-overview">
                <h1 className="text-primary fw-semibold mb-3">Overview</h1>
                <p>RxNT offers healthcare software for electronic health records, practice management, and e-prescribing. Previously hindered by legacy .Net applications, RxNT faced slow development and high maintenance costs.</p>
            </div>,

            <div key="rxnt-solution">
                <h1 className="text-primary fw-semibold mb-3">The challenge and our solution</h1>
                <p>ACube modernized RxNT's technology, leading to significant growth and inclusion in the Inc. 5000 list of “Fastest-Growing Private Companies.”</p>
            </div>,

            <div key="rxnt-expertise">
                <h1 className="text-primary fw-semibold mb-3">Expertise</h1>
                <ul>
                    <li><span className="fw-semibold">Data Migration:</span> Migrated to the MERN stack.</li>
                    <li><span className="fw-semibold">System Compatibility:</span> Integrated with third-party systems.</li>
                    <li><span className="fw-semibold">Performance Optimization:</span> Enhanced for high user loads.</li>
                </ul>
                <p>Results:</p>
                <ul>
                    <li><span className="fw-semibold">Revenue Growth:</span> Increased financial performance.</li>
                    <li><span className="fw-semibold">eRx Volume:</span> Over 80 million prescriptions processed.</li>
                    <li><span className="fw-semibold">Claims Processing:</span> $200 million in claims annually.</li>
                </ul>
            </div>
        ],
        ProductMockImg: rxntmockupImg,
        ProductMockImgAlt: "rxntmockupImgAlt",
        ProductTechImg: rxnttechImg,
        ProductTechImgAlt: "rxnttechImgAlt",
    },

    // healthcare - hopscotch
    {
        productParent: "healthcare",
        productName: "hopscotch",
        title: "Hopscotch",
        productContent: [
            <div key="hopscotch-overview">
                <h1 className="text-primary fw-semibold mb-3">Overview</h1>
                <p>Hopscotch specializes in supporting children and adolescents with mental health concerns. They provide expert care with the help of specialized software and tools designed to enhance the quality of care for young patients.</p>
            </div>,

            <div key="hopscotch-solution">
                <h1 className="text-primary fw-semibold mb-3">The challenge and our solution</h1>
                <p>ACube developed a tailored software solution for Hopscotch, improving their clinical workflows and data management, which enabled providers to deliver more efficient and personalized care to their young patients.</p>
            </div>,

            <div key="hopscotch-expertise">
                <h1 className="text-primary fw-semibold mb-3">Expertise</h1>
                <p>ACube’s expertise in custom software development enhanced Hopscotch’s operational efficiency and support systems, ensuring better care delivery and streamlined processes for handling mental health concerns in children.</p>
            </div>

        ],
        ProductMockImg: hopscotchmockupImg,
        ProductMockImgAlt: "hopscotchmockupImgAlt",
        ProductTechImg: hopscotchtechImg,
        ProductTechImgAlt: "hopscotchtechImgAlt",
    },

    // education - svhs
    {
        productParent: "education",
        productName: "svhs",
        title: "Silicon Valley High School (SVHS)",
        productContent: [
            <div key="svhs-overview">
                <h1 className="text-primary fw-semibold mb-3">Overview</h1>
                <p>Founded in 2008, Silicon Valley High School (SVHS) offers a catalog of online courses and supports schools across the US with its flipped classroom learning management system and curriculum of over 60 video-based, self-paced courses.</p>
            </div>,

            <div key="svhs-solution">
                <h1 className="text-primary fw-semibold mb-3">The challenge and our solution</h1>
                <p>Since 2009, ACube has collaborated with SVHS to enhance educational platforms, providing innovative technological solutions for online education.</p>
            </div>,

            <div key="svhs-expertise">
                <h1 className="text-primary fw-semibold mb-3">Expertise</h1>
                <p>We developed AI tools like tutors, writing assistants, and academic integrity systems to support personalized learning. These innovations helped SVHS deliver flexible, high-quality education to over 46,000 students globally, while easing administrative tasks for educators.</p>
            </div>

        ],
        ProductMockImg: svhsmockupImg,
        ProductMockImgAlt: "svhsmockupImgAlt",
        ProductTechImg: svhstechImg,
        ProductTechImgAlt: "svhstechImgAlt",
    },

    // fintech - D-Risk
    {
        productParent: "fintech",
        productName: "D-Risk",
        title: "D-Risk",
        productContent: [
            <div key="d-risk-overview">
                <h1 className="text-primary fw-semibold mb-3">Overview</h1>
                <p>D-Risk is a FinTech AI platform that automates the manual process of creating credit rating reports for companies, offering instantaneous reports.</p>
            </div>,

            <div key="d-risk-solution">
                <h1 className="text-primary fw-semibold mb-3">The challenge and our solution</h1>
                <p>D-Risk required PhD-level research to replicate the manual process with 90% accuracy. While the AI engine was developed, commercialization and usability gaps remained.</p>
            </div>,

            <div key="d-risk-expertise">
                <h1 className="text-primary fw-semibold mb-3">Expertise</h1>
                <p>Engenesis created a clickable UI/UX prototype and structured an API-based system, allowing users in the financial industry to leverage the AI. The product is now in pilot with various banks after passing regulatory hurdles.</p>
            </div>

        ],
        ProductMockImg: dRiskmockupImg,
        ProductMockImgAlt: "driskmockupImgAlt",
        ProductTechImg: dRisktechImg,
        ProductTechImgAlt: "drisktechImgAlt",
    },

    {
        productParent: "fintech",
        productName: "qicsend",
        title: "QicSEND",
        productContent: [
            <div key="qicsend-overview">
                <h1 className="text-primary fw-semibold mb-3">Overview</h1>
                <p>QicSEND enables secure money transfers from Canada to 42+ countries, including the Philippines, India, Sri Lanka, and Pakistan, offering a simple and reliable solution for international transfers.</p>
            </div>,

            <div key="qicsend-solution">
                <h1 className="text-primary fw-semibold mb-3">The challenge and our solution</h1>
                <p>QicSEND simplifies international transfers with a user-friendly platform that ensures secure transactions, meeting Canadian regulations and protecting personal and financial data.</p>
            </div>,

            <div key="qicsend-expertise">
                <h1 className="text-primary fw-semibold mb-3">Expertise</h1>
                <p>Powered by Mercury Mercantile Technologies, QicSEND leverages a leadership team with deep international business expertise, ensuring reliability, security, and regulatory compliance.</p>
            </div>

        ],
        ProductMockImg: qicsendmockupImg,
        ProductMockImgAlt: "qicsendmockupImgAlt",
        ProductTechImg: qicsendtechImg,
        ProductTechImgAlt: "qicsendtechImgAlt",
    },

    {
        productParent: "fintech",
        productName: "ramp",
        title: "Ramp",
        productContent: [
            <div key="ramp-overview">
                <h1 className="text-primary fw-semibold mb-3">Overview</h1>
                <p>Founded in 2019, Ramp helps finance teams build healthier businesses through an all-in-one platform that integrates corporate cards and finance operations software, streamlining processes for businesses of all sizes.</p>
            </div>,

            <div key="ramp-solution">
                <h1 className="text-primary fw-semibold mb-3">The challenge and our solution</h1>
                <p>Ramp’s integrated solution combines corporate cards with finance software, saving money and freeing finance teams to focus on impactful work, modernizing back-office operations for efficient business growth.</p>
            </div>,

            <div key="ramp-expertise">
                <h1 className="text-primary fw-semibold mb-3">Expertise</h1>
                <p>As one of the fastest-growing corporate card and bill payment platforms, Ramp processes tens of billions annually, simplifying finance operations to save time and money for businesses.</p>
            </div>

        ],
        ProductMockImg: rampmockupImg,
        ProductMockImgAlt: "rampmockupImgAlt",
        ProductTechImg: ramptechImg,
        ProductTechImgAlt: "ramptechImgAlt",
    },

    //real estate - Openn
    {
        productParent: "realEstate",
        productName: "openn",
        title: "Openn",
        productContent: [
            <div key="openn-overview">
                <h1 className="text-primary fw-semibold mb-3">Overview</h1>
                <p>Openn Negotiation Limited (ASX: OPN) is a PropTech company offering a cloud-based platform that helps real estate agents sell properties online with enhanced transparency. The platform streamlines negotiations, provides digital contracts, and offers real-time updates for buyers and sellers.</p>
            </div>,

            <div key="openn-solution">
                <h1 className="text-primary fw-semibold mb-3">The challenge and our solution</h1>
                <p>ACube optimized Openn's platform by improving real-time updates, enhancing communication tools, and streamlining digital contracts. This led to a more seamless and transparent property negotiation process for users.</p>
            </div>,

            <div key="openn-expertise">
                <h1 className="text-primary fw-semibold mb-3">Expertise</h1>
                <p>ACube leveraged its expertise in cloud-based solutions and PropTech to enhance Openn’s infrastructure, ensuring a secure, scalable, and efficient platform for property negotiations.</p>
            </div>

        ],
        ProductMockImg: opennmockupImg,
        ProductMockImgAlt: "opennmockupImgAlt",
        ProductTechImg: openntechImg,
        ProductTechImgAlt: "openntechImgAlt",
    },

    //real estate - Kobelco
    {
        productParent: "realEstate",
        productName: "kobelco",
        title: "Kobelco",
        productContent: [
            <div key="kobelco-overview">
                <h1 className="text-primary fw-semibold mb-3">Overview</h1>
                <p>Kobelco Construction Equipment India provides earth movers and cranes for construction sites, offering eco-friendly, tech-driven, and jobsite-specific solutions. Established in 2006, KCEI manufactures in Andhra Pradesh and has over 140 outlets in India, Nepal, and Bangladesh, ensuring robust service and parts availability.</p>
            </div>,

            <div key="kobelco-solution">
                <h1 className="text-primary fw-semibold mb-3">The challenge and our solution</h1>
                <p>ACube streamlined Kobelco’s service and parts management by enhancing their digital systems, ensuring faster support and improved communication across their vast network. This resulted in better service efficiency and customer satisfaction.</p>
            </div>,

            <div key="kobelco-expertise">
                <h1 className="text-primary fw-semibold mb-3">Expertise</h1>
                <p>ACube applied its expertise in industrial tech solutions, refining Kobelco’s operational processes with data-driven insights and cloud-based tools to optimize customer support and after-sales service.</p>
            </div>

        ],
        ProductMockImg: kobelcomockupImg,
        ProductMockImgAlt: "kobelcomockupImgAlt",
        ProductTechImg: kobelcotechImg,
        ProductTechImgAlt: "kobelcotechImgAlt",
    },

    //ecommerce - ASN
    {
        productParent: "ecommerce",
        productName: "asn",
        title: "ASN (Australian Sports Nutrition)",
        productContent: [
            <div key="asn-overview">
                <h1 className="text-primary fw-semibold mb-3">Overview</h1>
                <p>Founded in 2002, ASN has supported health, fitness, and nutrition goals across Australia and the USA for over 21 years. It goes beyond supplements, focusing on helping people transform their lives through quality products and education.</p>
            </div>,

            <div key="asn-solution">
                <h1 className="text-primary fw-semibold mb-3">The challenge and our solution</h1>
                <p>ASN provides solution-based supplement stacks for weight loss, muscle gain, and performance, along with tools like InBody Scanners and an Education Hub, ensuring customers receive top-tier support for their fitness journey.</p>
            </div>,

            <div key="asn-expertise">
                <h1 className="text-primary fw-semibold mb-3">Expertise</h1>
                <p>With over two decades of experience, ASN excels in sourcing, manufacturing, and delivering specialty supplements, offering expert advice and personalized customer care for real, effective results.</p>
            </div>

        ],
        ProductMockImg: asnmockupImg,
        ProductMockImgAlt: "asnmockupImgAlt",
        ProductTechImg: asntechImg,
        ProductTechImgAlt: "asntechImgAlt",
    },

    {
        productParent: "ecommerce",
        productName: "anatomie",
        title: "Anatomie",
        productContent: [
            <div key="anatomie-overview">
                <h1 className="text-primary fw-semibold mb-3">Overview</h1>
                <p>Anatomie, established in 2006 by Kate and Shawn Boyer, specializes in luxury travel apparel that blends style with function. Designed for modern travelers, their collections focus on lightweight, wrinkle-resistant fabrics that ensure comfort and durability.</p>
            </div>,

            <div key="anatomie-solution">
                <h1 className="text-primary fw-semibold mb-3">The challenge and our solution</h1>
                <p>Anatomie needed a modern e-commerce platform to enhance customer experience and scale globally. Acube optimized their website by integrating performance-driven features, streamlining backend processes, and improving user experience, making their luxurious travel apparel accessible to a wider audience.</p>
            </div>,

            <div key="anatomie-expertise">
                <h1 className="text-primary fw-semibold mb-3">Expertise</h1>
                <p>Acube’s expertise in e-commerce development, combined with cutting-edge technologies, enabled Anatomie to launch a seamless, high-performing online store. This improved global reach, resulting in higher engagement, conversions, and customer satisfaction.</p>
            </div>

        ],
        ProductMockImg: anatomiemockupImg,
        ProductMockImgAlt: "anatomiemockupImgAlt",
        ProductTechImg: anatomietechImg,
        ProductTechImgAlt: "anatomietechImgAlt",
    },

    //gps-tracking - Mobi Car Pooling
    {
        productParent: "gpsTracking",
        productName: "mobi-car-pooling",
        title: "Mobi Car Pooling",
        productContent: [
            <div key="mobi-overview">
                <h1 className="text-primary fw-semibold mb-3">Overview</h1>
                <p>Mobi is a ridesharing platform that connects drivers and riders for quick and affordable commuting. It aims to reduce the cost of travel by efficiently matching users for shared rides to work, university, or other destinations.</p>
            </div>,

            <div key="mobi-solution">
                <h1 className="text-primary fw-semibold mb-3">The challenge and our solution?</h1>
                <p>ACube enhanced Mobi’s platform by improving its algorithm for rider-driver matching, optimizing routes, and boosting overall platform reliability, ensuring a seamless and cost-effective ridesharing experience.</p>
            </div>,

            <div key="mobi-expertise">
                <h1 className="text-primary fw-semibold mb-3">Expertise</h1>
                <p>ACube applied its tech expertise to refine Mobi’s infrastructure, enhancing the platform’s efficiency, scalability, and user experience, which helped Mobi improve customer satisfaction and reduce operational costs.</p>
            </div>

        ],
        ProductMockImg: mobimockupImg,
        ProductMockImgAlt: "mobimockupImgAlt",
        ProductTechImg: mobitechImg,
        ProductTechImgAlt: "mobitechImgAlt",
    },

];

export const urlRouting = [
    {
        pathname: "/hospitality", pageName: "hospitality"
    },
    {
        pathname: "/healthcare", pageName: "healthcare"
    },
    {
        pathname: "/education", pageName: "education"
    },
    {
        pathname: "/real-estate", pageName: "realEstate"
    },
    {
        pathname: "/fintech", pageName: "fintech"
    },
    {
        pathname: "/gps-tracking", pageName: "gpsTracking"
    },
    {
        pathname: "/e-commerce", pageName: "ecommerce"
    },
]

const routeMetadata = {
    '/': { title: 'Home' },
    '/artificial-intelligence': { title: 'Artificial Intelligence' },
    '/web-app': { title: 'Web Development' },
    '/blockchain-app': { title: 'Blockchain' },
    '/cloud-strategy': { title: 'Cloud Strategy' },
    '/robotic-process-automation': { title: 'Robotic Process Automation' },
    '/devops': { title: 'DevOps' },
    '/uxui-services': { title: 'UI/UX Design' },
    '/mobile-app': { title: 'Mobile App Development' },
    '/software-testing': { title: 'Software Testing' },
    '/analytics': { title: 'Analytics' },
    '/contact-us': { title: 'Contact Us' },
    '/portfolio': { title: 'Portfolio' },

    // Hire Team pages
    '/hire-reactjs-developer': { title: 'Hire ReactJS Developer' },
    '/hire-angularjs-developer': { title: 'Hire AngularJS Developer' },
    '/hire-nodejs-developer': { title: 'Hire Node.js Developer' },
    '/hire-vuejs-developer': { title: 'Hire Vue.js Developer' },
    '/hire-uiux-developer': { title: 'Hire UI/UX Developer' },
    '/hire-golang-developer': { title: 'Hire GoLang Developer' },
    '/hire-aspnet-developer': { title: 'Hire ASP.NET Developer' },
    '/hire-java-developer': { title: 'Hire Java Developer' },
    '/hire-python-developer': { title: 'Hire Python Developer' },
    '/hire-php-developer': { title: 'Hire PHP Developer' },
    '/hire-flutter-developer': { title: 'Hire Flutter Developer' },
    '/hire-ionic-developer': { title: 'Hire Ionic Developer' },
    '/hire-ios-developer': { title: 'Hire IOS Developer' },
    '/hire-android-developer': { title: 'Hire Android Developer' },
    '/hire-rpa-developer': { title: 'Hire RPA Developer' },
    '/hire-ml-developer': { title: 'Hire Machine Learning Developer' },
    '/hire-devops-developer': { title: 'Hire DevOps Developer' },
    '/hire-powerbi-developer': { title: 'Hire Power BI Developer' },
    '/hire-awscloud-developer': { title: 'Hire AWS Cloud Developer' },
    '/hire-azurecloud-developer': { title: 'Hire Azure Cloud Developer' },
    '/hire-htmlcss-developer': { title: 'Hire HTML/CSS Developer' },
    '/hire-django-developer': { title: 'Hire Django Developer' },
    '/hire-csharp-developer': { title: 'Hire C# Developer' },
    '/hire-laravel-developer': { title: 'Hire Laravel Developer' },
    '/hire-dotnetcore-developer': { title: 'Hire .NET Core Developer' },
    '/hire-data-scientist': { title: 'Hire Data Scientist' },
    '/hire-data-engineer': { title: 'Hire Data Engineer' },
    '/hire-ai-developer': { title: 'Hire AI Developer' },
    '/hire-chatgpt-developer': { title: 'Hire ChatGPT Developer' },
    '/hire-openai-developer': { title: 'Hire OpenAI Developer' },
    '/hire-prompt-engineer': { title: 'Hire Prompt Engineer' },
    '/hire-aiops-engineer': { title: 'Hire AIOps Engineer' },
    '/hire-moodle-developer': { title: 'Hire Moodle Developer' },
    '/hire-reactnative-developer': { title: 'Hire React Native Developer' },

    // Portfolio pages
    '/portfolio/rxnt': { title: 'RXNT' },
    '/portfolio/frank': { title: 'Frank' },
    '/portfolio/svhs': { title: 'SVHS' },
    '/portfolio/tds': { title: 'TDS' },
    '/portfolio/tynax': { title: 'Tynax' },
    '/portfolio/anatomie': { title: 'Anatomie' },
    '/portfolio/vacsewcenter': { title: 'Vac Sew Center' },
    '/portfolio/wbes': { title: 'WBES' },
    '/portfolio/openn': { title: 'OpenN' },
    '/portfolio/willship': { title: 'Willship' },
};

export default routeMetadata;

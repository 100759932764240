import React from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import dRiskHero from '../../../../../images/solutions/middle-section/banners/d-risk-banner.webp';
import qicsendHero from '../../../../../images/solutions/middle-section/banners/qic-send-banner.webp';
import rampHero from '../../../../../images/solutions/middle-section/banners/ramp-banner.webp';
import { useAppContext } from "../../../../../context/app-context";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;

    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
}

const FintechCarousel = () => {
    const { setSelectedIndustryProd } = useAppContext()

    const slides = [
        {
            productName: "D-Risk",
            content: (
                <div className="text-center">
                    <img src={dRiskHero} alt="hero" className="img-fluid mx-auto" />
                </div>
            ),
        },
        {
            productName: "qicsend",
            content: (
                <div className="text-center">
                    <img src={qicsendHero} alt="hero" className="img-fluid mx-auto" />
                </div>
            ),
        },
        {
            productName: "ramp",
            content: (
                <div className="text-center">
                    <img src={rampHero} alt="hero" className="img-fluid mx-auto" />
                </div>
            ),
        },
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        afterChange: (current) => {
            setSelectedIndustryProd(slides[current].productName)
        },
    };

    return (
        <section className="solutions-carousel-section">
            <Container>
                <Slider {...settings}>
                    {slides.map((slide, index) => (
                        <div key={index}>{slide.content}</div>
                    ))}
                </Slider>
            </Container>
        </section>
    );
};

export default FintechCarousel;

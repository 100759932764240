import React, { createContext, useContext, useRef } from "react";

const ScrollContext = createContext();

export const useScroll = () => {
    return useContext(ScrollContext);
};

export const ScrollProvider = ({ children }) => {
    // Create a ref container to manage multiple refs

    const scrollToSection = (ref) => {
        if (ref && ref.current) {
            const element = ref.current;
            const rect = element.getBoundingClientRect();
            const scrollTop =
                window.pageYOffset || document.documentElement.scrollTop;
            const top = rect.top + scrollTop - 100;

            window.scrollTo({
                top,
                behavior: "smooth",
            });
        } else {
            console.error("Ref is not available:", ref);
        }
    };

    const contactform = useRef(null);
    const faq = useRef(null);
    const pricing = useRef(null);
    const contextValue = {
        contactform,
        faq,
        pricing,
        scrollToSection,
    };

    return (
        <ScrollContext.Provider value={contextValue}>
            {children}
        </ScrollContext.Provider>
    );
};